import { Tabs } from 'antd'

const { TabPane } = Tabs

export function SideMenu(props) {
    return (
        <div className="Tabs-container">
            <Tabs
                tabPosition="right"
                activeKey={props.typeId}
                onChange={props.changeTab}
            >
                <TabPane tab="Alimentatori" key="power-suply"></TabPane>
                <TabPane tab="Cornici" key="frame"></TabPane>
                <TabPane tab="Controllers" key="controller"></TabPane>
                <TabPane tab="Strisce LED" key="led-strip"></TabPane>
            </Tabs>
        </div>
    )
}
