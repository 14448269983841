import { useState, useEffect } from 'react'
import { Table, Input, Button, Space } from 'antd'
import {
    CloseCircleOutlined,
    CheckCircleOutlined,
    CheckOutlined,
    ExclamationCircleOutlined,
    WarningOutlined,
    SearchOutlined,
} from '@ant-design/icons'
import './App.css'

export function TableView(props) {
    const [columns, setColumns] = useState([])

    const handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter)
        props.setFilteredInfo(filters)
        props.setSortedInfo(sorter)
    }

    // const clearFilters = () => {
    //     props.setFilteredInfo({})
    // }

    // const clearAll = () => {
    //     props.setFilteredInfo({})
    //     props.setSortedInfo({})
    // }

    // If null assign a blank dictionary/Object
    if (!props.filteredInfo) {
        props.setFilteredInfo({})
    }
    if (!props.sortedInfo) {
        props.setSortedInfo({})
    }

    useEffect(() => {
        // getColumnsSearchProps is a dictionary containing: filterDropdown (box with input + buttons for filter), filter logic and reset filter
        // it's a dictionary to avoid repeating this code for every columns that needs a filter
        const getColumnSearchProps = (dataIndex) => ({
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                            )
                        }
                        onPressEnter={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() =>
                                handleSearch(
                                    selectedKeys,
                                    confirm,
                                    dataIndex,
                                    clearFilters
                                )
                            }
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => handleReset(clearFilters)}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered) => (
                <SearchOutlined
                    style={{
                        fontSize: filtered ? '25px' : '20px',
                        color: filtered ? '#ff1100' : '#00aaff',
                    }}
                />
            ),

            filteredValue: props.filteredInfo[dataIndex] || null,

            onFilter: (value, record) =>
                record[dataIndex]
                    ? record[dataIndex]
                          .toString()
                          .toLowerCase()
                          .includes(value.toLowerCase())
                    : '',
        })

        const handleSearch = (selectedKeys, confirm, dataIndex) => {
            let temp = props.filteredInfo
            temp[dataIndex] = selectedKeys
            props.setFilteredInfo(temp)

            console.log(props.filteredInfo)

            confirm()
        }

        const handleReset = (clearFilters) => {
            clearFilters()
        }

        const columnsTemp = [
            {
                title: 'Stato',
                key: 'refill',
                dataIndex: 'refill',
                align: 'center',
                width: '90px',
                sorter: {
                    compare: (a, b) => a.refill - b.refill,
                    multiple: 150,
                },
                sortOrder:
                    props.sortedInfo.columnKey === 'refill' &&
                    props.sortedInfo.order,
                render(refill) {
                    if (refill === 10) {
                        return {
                            props: {
                                style: {
                                    background: '#ff6b6b',
                                },
                            },
                            children: (
                                <WarningOutlined
                                    style={{
                                        fontSize: '30px',
                                    }}
                                />
                            ),
                        }
                    } else if (refill === 5) {
                        return {
                            props: {
                                style: {
                                    background: '#ffa14f',
                                },
                            },
                            children: (
                                <ExclamationCircleOutlined
                                    style={{
                                        fontSize: '30px',
                                    }}
                                />
                            ),
                        }
                    } else if (refill === 0) {
                        return (
                            <CheckOutlined
                                style={{
                                    fontSize: '20px',
                                }}
                            />
                        )
                    } else {
                        console.log('error refill value not valid')
                        return <h1>error</h1>
                    }
                },
            },
            {
                title: 'Qnt',
                dataIndex: 'qty',
                key: 'qty',
                width: '80px',
                sorter: {
                    compare: (a, b) => a.qty - b.qty,
                    multiple: 10,
                },
                sortOrder:
                    props.sortedInfo.columnKey === 'qty' &&
                    props.sortedInfo.order,
            },
            {
                title: 'Prodotto',
                dataIndex: 'name',
                key: 'name',
                sorter: {
                    compare: (a, b) => a.name.localeCompare(b.name),
                    multiple: 75,
                },
                sortOrder:
                    props.sortedInfo.columnKey === 'name' &&
                    props.sortedInfo.order,
                //add Filter for name
                ...getColumnSearchProps('name'),
            },
            {
                title: 'SKU',
                dataIndex: 'SKU',
                key: 'SKU',
                sorter: {
                    compare: (a, b) => a.SKU.localeCompare(b.SKU),
                    multiple: 50,
                },
                sortOrder:
                    props.sortedInfo.columnKey === 'SKU' &&
                    props.sortedInfo.order,
                //add Filter for SKU
                ...getColumnSearchProps('SKU'),
            },
            {
                title: 'Stock',
                dataIndex: 'is_in_stock',
                key: 'is_in_stock',
                align: 'center',
                width: '80px',
                sorter: {
                    compare: (a, b) => a.is_in_stock - b.is_in_stock,
                    multiple: 100,
                },
                sortOrder:
                    props.sortedInfo.columnKey === 'is_in_stock' &&
                    props.sortedInfo.order,
                render(stock, record) {
                    if (stock === 0 && record.qty > 0) {
                        return {
                            props: {
                                style: {
                                    background: '#fff1af',
                                },
                            },
                            children: (
                                <CloseCircleOutlined
                                    style={{
                                        fontSize: '30px',
                                    }}
                                />
                            ),
                        }
                    } else {
                        return (
                            <CheckCircleOutlined
                                style={{
                                    fontSize: '20px',
                                }}
                            />
                        )
                    }
                },
            },
        ]

        setColumns(columnsTemp)
    }, [props.filteredInfo, props.sortedInfo])

    return (
        <div className="Table-container">
            <Table
                bordered="true"
                dataSource={props.dataSource}
                columns={columns}
                pagination={{ pageSize: 100 }}
                scroll={{ y: '85vh' }}
                onChange={handleChange}
            />
        </div>
    )
}
