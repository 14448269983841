import { sha256 } from 'js-sha256'

export function calculateToken() {
    //  Calculate token valid for current hour
    const currentDate = new Date()
    const currentDateString =
        currentDate.getUTCFullYear().toString().padStart(4, '0') +
        (currentDate.getUTCMonth() + 1).toString().padStart(2, '0') +
        currentDate.getUTCDate().toString().padStart(2, '0') +
        currentDate.getUTCHours().toString().padStart(2, '0')

    const stringToEncode = 'refilled_' + currentDateString
    return sha256(stringToEncode)
}
