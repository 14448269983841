import { useState, useEffect } from 'react'
import { Checkbox } from 'antd'

import axios from 'axios'
import qs from 'qs'

import { SideMenu } from './SideMenu'
import { TableView } from './TableView'
import { calculateToken } from './Utils'

import './App.css'

// Refill tool logic, visualization of view implemented on "TableView", Menu with links on "SideMenu"
export function Refill(props) {
    const [dataSource, setDataSource] = useState([])
    const [typeId, setTypeId] = useState('default')
    const [showAll, setShowAll] = useState(false)
    const [filteredInfo, setFilteredInfo] = useState({})
    const [sortedInfo, setSortedInfo] = useState({})

    useEffect(() => {
        console.log(props)
        // On page load check if url contains a typeId
        if (
            props.match.params.typeId !== null &&
            props.match.params.typeId !== 'undefined'
        ) {
            setTypeId(props.match.params.typeId)
        } else {
            setTypeId('power-supply')
        }
    }, [])

    useEffect(() => {
        // Get token
        const token = calculateToken()

        // Avoid double downloading data from server (on page load + on link check)
        // console.log(typeId)
        setFilteredInfo({})
        setSortedInfo({})

        if (typeId !== 'default') {
            // Get datasource
            axios
                .post(
                    'https://store.ledpro.it/tools/refill/stock/',
                    qs.stringify({
                        token: token,
                        type: typeId,
                        show_all: showAll,
                    })
                )
                .then(function (response) {
                    console.log(response)

                    let productList = response.data

                    productList.forEach((product, index) => {
                        // Add unique value to rows
                        productList[index]['key'] = product['SKU']
                        // Convert qty to integer
                        productList[index]['qty'] = parseInt(product['qty'])
                        // Convert notify_stock_qty to interger
                        productList[index]['notify_stock_qty'] = parseInt(
                            product['notify_stock_qty']
                        )
                        // Control the notify stock quantity to check if refill is needed
                        productList[index]['refill'] = 0
                        if (
                            productList[index]['qty'] <=
                            productList[index]['notify_stock_qty']
                        ) {
                            productList[index]['refill'] = 10
                        } else if (
                            productList[index]['qty'] <=
                            Math.ceil(
                                productList[index]['notify_stock_qty'] * 1.5
                            )
                        ) {
                            productList[index]['refill'] = 5
                        }
                    })

                    setDataSource(productList)
                })
                .catch(function (error) {
                    console.log(error)
                    setDataSource([])
                })
        }
    }, [typeId, showAll])

    // Handles url changes from SideMenu, updating typeId and Url
    function changeTab(key) {
        setTypeId(key)
        props.history.push('/' + key)
    }

    return (
        <div className="View-container">
            <TableView
                dataSource={dataSource}
                filteredInfo={filteredInfo}
                setFilteredInfo={setFilteredInfo}
                sortedInfo={sortedInfo}
                setSortedInfo={setSortedInfo}
            />

            <div className="Sidebar-container">
                <SideMenu typeId={typeId} changeTab={changeTab} />

                {/* ShowAll checkbox for testing purposes */}
                <div className="ShowAll-container">
                    <Checkbox
                        checked={showAll}
                        onChange={() => setShowAll(!showAll)}
                    >
                        Show All
                    </Checkbox>
                </div>
                {/* <button onClick={() => setFilteredInfo({})}>
                    Reset Filters
                </button> */}
            </div>
        </div>
    )
}
