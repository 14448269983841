import {
    BrowserRouter as Router,
    Route,
    Redirect,
    useHistory,
} from 'react-router-dom'
import { useCallback } from 'react'
import { Refill } from './Refill'

import './App.css'

// App is responsable for routing
function App(props) {
    const history = useHistory()
    const handlePush = useCallback((key) => history.push('/' + key), [history])

    return (
        <Router>
            <Redirect from="/" to="/power-supply" exact />
            <Route
                path="/:typeId"
                component={Refill}
                pushHistory={handlePush}
            ></Route>
        </Router>
    )
}

export default App
